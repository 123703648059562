import { Text } from "@chakra-ui/react";
import { Link } from "@remix-run/react";

export const FooterLink = ({ text, linkText, href }: { text?: string; linkText: string; href: string }) => {
  return (
    <Text fontSize="sm" textAlign="center" width="full">
      {text}{" "}
      <Link to={href}>
        <Text as="span" color="gray.800" textDecor="underline">
          {linkText}
        </Text>
      </Link>
    </Text>
  );
};
