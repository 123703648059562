import { VStack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export const AuthActionWrapper = ({ children }: PropsWithChildren<{}>) => {
  return (
    <VStack id="action-wrapper" width="full" alignItems="stretch" mt={1}>
      {children}
    </VStack>
  );
};
