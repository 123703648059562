// * EXTERNAL IMPORTS
import {
  Image,
  Text,
  AlertProps,
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Collapse,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { ValidatedForm } from "remix-validated-form";

// * INTERNAL IMPORTS
import IconLogo from "public/images/icon-logo.png";
import Alert from "components/Alert";

// * TYPES
export type AuthLayoutProps = {
  title: string;
  subtitle: string;
  urlError?: string | null;
  bodyContent: JSX.Element;
  footerContent: JSX.Element;
};

// * COMPONENT
export function AuthLayout({ title, subtitle, urlError, bodyContent, footerContent }: AuthLayoutProps) {
  return (
    <Box as="main" width="100%" minHeight="100vh" py={8} bg="gray.100" display="flex" flexDirection="column" justifyContent="center">
      <Container maxW="lg">
        <Collapse in={!!urlError} animateOpacity>
          <Alert status="error" title="Auth Error" description={urlError || ""} mb={6} />
        </Collapse>
        <Card p={4}>
          <CardHeader as={VStack} spacing={6} pb={3}>
            <Image id="logo-image" src={IconLogo} alt="CopyFrame Logo" width="2.75rem" height="2.75rem" mb={1} />
            {/* <HiOutlineRectangleGroup size={56} strokeWidth={1} stroke="var(--chakra-colors-blue-600)" /> */}
            <VStack id="header-text">
              <Heading as="h1" fontSize={28}>
                {title}
              </Heading>
              <Text>{subtitle}</Text>
            </VStack>
          </CardHeader>
          <CardBody>{bodyContent}</CardBody>
          <CardFooter pt={2}>
            <VStack width="100%">{footerContent}</VStack>
          </CardFooter>
        </Card>
      </Container>
    </Box>
  );
}
